export const seo = {
  url: 'firma/omida-group',
  title: {
    pl: 'Omida Group',
    en: 'Omida Group',
    ua: 'Omida Group',
  },
  desc: {
    pl: `Omida Group jest jedną z najbardziej rozpoznawalnych marek w branży TSL w Polsce. Nasza siła tkwi w ludziach, dla których spedycja i transport to coś więcej niż tylko praca – to zamiłowanie i pasja.`,
    en: `Omida Group is one of the most recognizable brands in the TSL industry in Poland. Our strength lies in people for whom forwarding and transport is something more than just work - it is passion.`,
    ua: `Omida Group є одним із найбільш впізнаваних брендів у промисловості TSL у Польщі. Наша сила – в людях, для яких експедиція та транспорт – це щось більше, ніж просто робота – це любов і пристрасть.`,
  },
  keywords: [
    'omida',
    'omida group',
    'omida sea & air',
    'omida logistics',
    'starklog',
    'cargonite',
    // 'bpo champions',
  ],
}

export const intro = {
  title: {
    pl: 'Omida Group',
    en: 'Omida Group',
    ua: 'Omida Group',
  },
  desc: {
    pl: `Omida Group jest jedną z najbardziej rozpoznawalnych marek w branży TSL w Polsce. Nasza siła tkwi w ludziach, dla których spedycja i transport to coś więcej niż tylko praca – to zamiłowanie i pasja.`,
    en: `Omida Group is one of the most recognizable brands in the TSL industry in Poland. Our strength lies in people for whom forwarding and transport is something more than just work - it is passion.`,
    ua: `Omida Group є одним із найбільш впізнаваних брендів у промисловості TSL у Польщі. Наша сила – в людях, для яких експедиція та транспорт – це щось більше, ніж просто робота – це любов і пристрасть.`,
  },
}

export const main = {
  title: {
    pl: 'Jesteśmy częścią<br/> Omida Group',
    en: 'We are part of<br/> Omida Group ',
    ua: 'Ми є частиною<br/> Omida Group ',
  },
  body: {
    pl: 'Omida Group składa się z kilku spółek, które poprzez ścisłą współpracę i wymianę doświadczeń są w stanie zapewnić Klientom kompleksowość rozwiązań z zakresu spedycji oraz każdej gałęzi transportu w kraju i za granicą.',
    en: 'Omida Group consists of several companies, which through close cooperation and exchange of experience are able to provide customers with comprehensive solutions in the field of freight forwarding and every branch of transport at home and abroad.',
    ua: 'Omida Group складається з кількох компаній, які завдяки тісній співпраці та обміну досвідом здатні надавати клієнтам комплексні рішення у сфері експедирування та кожного виду транспорту в країні та за кордоном.',
  },
  sections: [
    {
      title: {
        pl: 'Omida Sea And Air S.A.',
        en: 'Omida Sea And Air S.A.',
        ua: 'Omida Sea And Air S.A.',
      },
      desc: {
        pl: `
        <p>
          Omida Sea And Air S.A. oferuje swoim Klientom pełen zakres usług związanych z obsługą spedycyjną transportu morskiego, lotniczego i kolejowego oraz najszerszy zakres ubezpieczeń w transporcie.
        </p>
        <p>
          Największy udział w działalności spółki stanowi przewóz ładunków skonteneryzowanych FCL, LCL oraz obsługa frachtu lotniczego.
        </p>
        <p>
          W działalności spółki odnotowuje się dynamiczny wzrost w obsłudze frachtów kolejowych (FCL/LCL) w relacji Chiny-Polska-Chiny.
        </p>
        <ul>
          <li>
            150 pracowników
          </li>
          <li>
            300 biur partnerskich na świecie
          </li>
          <li>
          33 458 TEU w 2022
          </li>
        </ul>
        `,
        en: `
        <p>
          Omida Sea And Air S.A. offers its customers a full range of sea, air and rail freight forwarding services and the widest range of transport insurance.
        </p>
        <p>
          The largest share of the company's activity is the transport of containerized FCL, LCL cargo and air freight services.
        </p>
        <p>
          The company's operations are noting dynamic growth in the service of rail freight (FCL/LCL) in China-Poland-China relations.
        </p>
        <ul>
          <li>
          150 employees
          </li>
          <li>
            300 partner offices worldwide
          </li>
          <li>
          33 458 TEU in 2022
          </li>
        </ul>
        `,
        ua: `
        <p>
          Omida Sea And Air S.A. пропонує своїм клієнтам повний спектр послуг, пов'язаних з морськими, повітряними та залізничними перевезеннями, а також найширший спектр страхування на транспорті.
        </p>
        <p>
          Найбільшу питому вагу в діяльності компанії займають перевезення вантажів у контейнерах FCL, LCL та авіаперевезення.
        </p>
        <p>
          Діяльність компанії демонструє динамічне зростання послуг залізничних вантажних перевезень (FCL / LCL) у відносинах Китай-Польща-Китай.
        </p>
        <ul>
          <li>
            150 працівників
          </li>
          <li>
            300 партнерських офісів по всьому світу
          </li>
          <li>
            33 458 TEU у 2022 році
          </li>
        </ul>
        `,
      },
    },
    {
      title: {
        pl: 'Omida Logistics',
        en: 'Omida Logistics',
        ua: 'Omida Logistics',
      },
      desc: {
        pl: `
        <p>
          Omida Logistics dzięki nowoczesnej flocie pojazdów, wykwalifikowanej kadrze oraz profesjonalnemu zapleczu logistycznemu, gwarantuje swoim Klientom najwyższy standard usług logistycznych.
        </p>
        <p>
          Spółka posiada kilkanaście oddziałów w całej Polsce, specjalizujących się w obsłudze określonych krajów.
        </p>
        <ul>
          <li>
            250 pracowników
          </li>
          <li>
          84 975 zrealizowanych transportów w 2022 roku
          </li>
          <li>
            850 zestawów floty
          </li>
        </ul>
        `,
        en: `
        <p>
          Omida Logistics, thanks to its modern fleet of vehicles, qualified staff and professional logistics facilities, guarantees its customers the highest standard of logistics services.
        </p>
        <p>
          The company has over a dozen branches all over Poland, specializing in serving specific countries.
        </p>
        <ul>
          <li>
            250 employees
          </li>
          <li>
          84 975 transports completed in 2022
          </li>
          <li>
            850 fleet sets
          </li>
        </ul>
        `,
        ua: `
        <p>
          Omida Logistics, завдяки сучасному автопарку, кваліфікованому персоналу та професійній матеріально-технічній базі, гарантує своїм клієнтам найвищий рівень логістичних послуг.
        </p>
        <p>
          Компанія має понад десяток відділень по всій Польщі, які спеціалізуються на обслуговуванні окремих країн.
        </p>
        <ul>
          <li>
            250 працівник
          </li>
          <li>
            У 2022 році виконано 84 975 перевезень
          </li>
          <li>
            850 комплектів автопарку
          </li>
        </ul>
        `,
      },
    },
    // {
    //   title: {
    //     pl: 'Virtus Logistics',
    //     en: 'Virtus Logistics',
    //   },
    // },
    // {
    //   title: {
    //     pl: 'BPO Champions',
    //     en: 'BPO Champions',
    //   },
    // },
    // {
    //   title: {
    //     pl: 'CARGONITE',
    //     en: 'CARGONITE',
    //   },
    //   desc: {
    //     pl: '',
    //     en: '',
    //   },
    // },
    // {
    //   title: {
    //     pl: 'Stark Log',
    //     en: 'Stark Log',
    //   },
    //   desc: {
    //     pl: `
    //     <p>
    //     Stark Log to zespół wykwalifikowanych w swojej dziedzinie specjalistów, zajmujących się transportem drogowym na rynkach nordyckich i bałtyckich w grupie Omida. Oferuje codzienne połączenia z/do Polski i krajów sąsiedzkich.
    //     </p>
    //     <p>
    //     Świadczy usługi transportu kabotażowego oraz między krajami nordyckimi.
    //     </p>
    //     <p>
    //     Stark Log swoją ofertę kieruje zarówno na przewóz całopojazdowy FTL jak i transport częściowy LTL dzięki regularnej linii drobnicowej. Oferuje serwis ekspresowy w ramach transportu dedykowanego oraz transport ponadnormatywny.
    //     </p>
    //     <p>
    //     Dodatkowo zapewnia wszelkie usługi doradcze oraz organizację odprawy celnej eksport/import na kierunku norweskim.
    //     </p>
    //     <ul>
    //       <li>
    //       24 pracowników
    //       </li>
    //       <li>
    //       13 786 zrealizowanych transportów w 2018 roku
    //       </li>
    //       <li>
    //       138 zestawów floty z systemem GPS
    //       </li>
    //     </ul>
    //     `,
    //     en: `
    //     <p>
    //     Stark Log is a team of qualified specialists in road transport in the Nordic and Baltic markets within the Omida Group. It offers daily connections from/to Poland and neighbouring countries.
    //     </p>
    //     <p>
    //     It provides cabotage transport services as well as between the Nordic countries.
    //     </p>
    //     <p>
    //     Stark Log targets both FTL as well as LTL customers with the use of its general cargo line. It offers express service in dedicated transport and oversize transport.
    //     </p>
    //     <p>
    //     Additionally, it provides comprehensive advisory services and organizes export/import customs clearance in the direction of Norway.
    //     </p>
    //     <ul>
    //       <li>
    //       24 employees
    //       </li>
    //       <li>
    //       13 786 transports completed in 2018
    //       </li>
    //       <li>
    //       138 fleet sets with GPS
    //       </li>
    //     </ul>
    //     `,
    //   },
  ],
}
